<template>
  <div class="container">
    <pcPublicHeader pageKey="notice" />
    <div class="public-content">
      <div class="page-title">消息通知</div>
      <div class="table-item">
        <div class="list-item">
          <div class="item" v-for="(item, index) in noticeList" :key="index">
            <div class="content"><div v-html="item.CnContent"></div></div>
            <div class="time">{{ $TOOL.dateFormat(item.AddTime * 1000) }}</div>
          </div>
        </div>
        <div v-if="total > PageCount" class="pagination-item">
          <el-pagination background layout="prev, pager, next" :page-size="PageCount" :total="total" @current-change="handleCurrentPageChange"></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import pcPublicHeader from '@/components/pcPublicHeader'

export default {
  name: "Notice",
  components: {
    pcPublicHeader,
  },
  data() {
    return {
      PageCount: 10,
      Page: 1,
      total: 0,
      loading: false,
      finish: false,
      noticeList: []
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  filters: {
    
  },
  mounted() {
    this.handleGetIncomeLogList()
  },
  methods: {
    ...mapActions(['GetUserInfo']),
    handleCurrentPageChange(val) {
      this.page = val
      this.handleGetIncomeLogList()
    },
    async handleGetIncomeLogList() {
      const { PageCount, Page } = this
      const params = {
        PageCount,
        Page,
        IsPc: 1
      }
      var res = await this.$API.noticeList.post(params)
      this.noticeList = [
        ...res.info
      ]
      this.total = res.count
      this.Page = Page + 1
      this.finish = res.info.length < PageCount
      this.loading = false
    }
  }
};
</script>

<style lang="less" scoped>
.page-title {
  padding: 30px 0 22px;
  line-height: 48px;
  font-size: 32px;
  font-weight: bold;
  color: #3d3d42;
}
.table-item {
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  .list-item {
    .item {
      padding: 30px 0;
      border-bottom: 1px solid #e9edf5;
      font-size: 16px;
      line-height: 22px;
      .content {
        font-weight: bold;
        color: #3D3D42;
      }
      .time {
        text-align: right;
        color: #9fafc1;
        margin-top: 12px;
      }
    }
  }
}
</style>
